<template>
  <div>
    <div v-if="!isShow">
      <vxe-table
        ref="xTableOrder"
        border
        show-overflow
        resizable
        height="500"
        :data="tableData"
        align="center"
        class="mb-35"
        :edit-rules="rulesOrder"
        :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
      >
        <vxe-column type="seq" title="序号" width="60" />
        <vxe-column field="transferOrderCode" title="调拨单号" />
        <vxe-column field="transferTypeI18" title="调拨类型" />
        <vxe-column field="outStockChannelName" title="调出备货渠道" />
        <vxe-column field="inStockChannelName" title="调入备货渠道" />
        <vxe-column field="outWarehouseName" title="调出仓库" />
        <vxe-column field="inWarehouseName" title="调入仓库" />
        <vxe-column field="replenishNumber" title="预计调拨数量" />
        <vxe-column field="expectReleaseDate" title="期望上架日期" :edit-render="{}" width="160">
          <template #default="{ row }">
            <span>{{ row.expectReleaseDate }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model="row.expectReleaseDate"
              :disabled-method="disabledDateMethod"
              type="date"
              placeholder="请选择日期"
              transfer
            />
          </template>
        </vxe-column>
        <vxe-column field="remark" title="备注" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input v-model="row.remark" :maxlength="256" placeholder="输入内容" type="text" />
          </template>
        </vxe-column>
      </vxe-table>
      <div class="btn_bottom">
        <el-button @click="$emit('updadeIsActiveNum', 2)">上一步</el-button>
        <el-button type="primary" :loading="loading" @click="submitForm">提交</el-button>
      </div>
    </div>
    <div v-else class="main_page">
      <i class="el-icon-circle-check" style="font-size: 50px; margin-top: 50px; color: #1890ff; margin-bottom: 25px; " />
      <h3>成功生成调拨单</h3>
      <div>调拨单号：
        <span v-for="(item, index) in tableData" :key="item.transferOrderCode">
          <router-link
            :to="{ path: '/replenishment-allocation-management/transfer-order-management/details', query: { transferOrderCode: item.transferOrderCode }}"
            style="color:#1890ff;"
          >{{ item.transferOrderCode }}</router-link>
          <span v-if="index != tableData.length -1">、</span>
          &nbsp;&nbsp;
        </span>
      </div>
      <div class="mt-4">调拨单生成成功，可立即前往转仓库作业</div>
      <div v-if="continueData && continueData.replenishDemand">当前Style、备货渠道仍有补货需求，可继续补货</div>

      <div class="mt-15" style="margin-top: 15px">
        <el-button @click="$router.go(-1)">完成</el-button>
        <el-button type="primary" @click="continueBtn">转仓库作业</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { postReplenishCommit } from '@/api/supply'
import VXETable from 'vxe-table'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      continueData: {},
      isShow: false,
      rulesOrder: {
        expectReleaseDate: [
          { required: true, message: '请选择' }
        ]
      }
    }
  },
  computed: {

    tableData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    continueBtn() {
      // dsb06
      const selectTosure = this.tableData.map(item => item.transferOrderCode)
      this.$router.push({
        path: '/replenishment-allocation-management/transfer-order-management/to-warehouse',
        append: 'true',
        query: { 'selectTosure': selectTosure }
      })
    },
    // 禁用日期
    disabledDateMethod({ date }) {
      return dayjs(dayjs(date).format('YYYYMMDD')).diff(dayjs().format('YYYYMMDD'), 'day') < 0
    },
    async submitForm(formName) {
      const $table = this.$refs.xTableOrder
      const errMap = await $table.validate(true).catch(errMap => errMap)
      if (errMap) {
        VXETable.modal.message({ status: 'error', content: '校验不通过！' })
      } else {
        VXETable.modal.message({ status: 'success', content: '校验成功！' })

        try {
          this.loading = true
          const { datas } = await postReplenishCommit({
            commitList: this.tableData
          })
          this.isShow = true
          this.continueData = datas || {}
        } finally {
          this.loading = false
        }
      }
    }
  }

}
</script>
<style scoped lang="scss">
  .main_page {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 400px;
  }
  .btn_bottom {
    display: flex;
    justify-content: center;
  }
</style>
