<template>
  <div>
    <el-steps :active="isActiveNum" align-center class="mb-35" process-status="finish">
      <el-step title="调入仓确认" />
      <el-step title="调出数量确认" />
      <el-step title="结果复核" />
      <el-step title="生成调拨单" />
    </el-steps>
    <div v-show="isActiveNum==0">
      <Step1
        v-model="step1Datas"
        :loading="loading"
        :stock-channel-name="stockChannelName"
        @getWarehouseNameSet="val=>warehouseNameSet=val"
        @chooseWarehouse="handleChooseWarehouse"
      />
    </div>

    <div v-show="isActiveNum==1 || isActiveNum==2">
      <Step23
        :warehouse-name-set="warehouseNameSet"
        :is-active-num="isActiveNum"
        :step-params="step23Params"
        @getOrderData="val=>step4Datas=val"
        @updadeIsActiveNum="val=>isActiveNum=val"
      />
    </div>

    <div v-show="isActiveNum==3">
      <Step4 v-model="step4Datas" @updadeIsActiveNum="val=>isActiveNum=val" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Step1 from './components/Step1'
import Step23 from './components/Step23'
import Step4 from './components/Step4'

export default {
  components: { Step1, Step23, Step4 },
  data() {
    return {
      step23Params: {},
      warehouseNameSet: [],
      step4Datas: [],
      step23Datas: [],
      stockChannelName: '',
      loading: false,
      isActiveNum: 0,
      step1Datas: []
    }
  },
  computed: {
    ...mapGetters([
      'replenishmentObject'
    ])
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(params) {
      try {
        this.loading = true
        const { replenishmentObject: { result }} = this
        const { replenishWarehouseList, stockChannelName } = JSON.parse(result)
        console.log('replenishWarehouseList: ', replenishWarehouseList)
        this.step1Datas = replenishWarehouseList
        this.stockChannelName = stockChannelName
        this.loading = false
      } finally {
        this.loading = false
      }
    },

    handleChooseWarehouse(list) {
      this.step23Params = list
      // this.step23Datas = list
      this.isActiveNum = 1
    }

  }
}
</script>

<style lang="scss" scope>
  .mb-35 {
    margin-bottom: 35px;
  }

  /*vxe-table 自定义行高 */
  .vxe-table--render-default.size--small .vxe-body--column.col--ellipsis>.vxe-cell {
  max-height:80px;
  padding:10px;
  }
</style>
