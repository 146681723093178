<template>
  <div>
    <el-dialog
      title="一键补货"
      :visible.sync="visible"
      width="900px"
      center
      :close-on-click-modal="false"
    >
      <vxe-table
        ref="xTable"
        border
        max-height="1000"
        class="sortable-row-demo"
        :data="$store.getters.warehouseNameSetStore"
        align="center"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxChange"
      >
        <vxe-table-column type="checkbox" width="80" />
        <vxe-table-column field="warehouseName" title="仓库" />
      </vxe-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils'

export default {
  props: {

    value: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      multipleSelection: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }

  },
  watch: {
    'value'(val) {
      if (val) {
        this.setCheckbox()
      }
    }

  },

  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  },
  methods: {
    setCheckbox() {
      this.$nextTick(() => {
        this.$refs.xTable.setCheckboxRow(
          this.$store.getters.warehouseNameSetStore,
          true
        )
      })
      this.multipleSelection = [...this.$store.getters.warehouseNameSetStore]
    },

    handleCheckboxChange({ records }) {
      this.multipleSelection = records
    },

    async submit() {
      if (!this.multipleSelection.length) return this.$message.warning('至少选择一个仓库')
      this.$emit('updateTableDatas', this.multipleSelection)
      this.visible = false
    }

  }
}
</script>

<style lang="scss" scope>

</style>
