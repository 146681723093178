<template>
  <div>
    <Suggestion
      v-model="suggestionDialogVisible"
      @updateTableDatas="handleUpdateTableDatas"
    />

    <el-row v-show="isActiveNum === 1">
      <el-col :span="8">
        <el-button
          type="text"
          class="mr-3"
          :disabled="tableLoading"
          @click="changeSuggest('Suggest')"
        >补货建议生成</el-button>

        <el-button
          v-if="singleStyle"
          type="text"
          :disabled="tableLoading"
          @click="handleOneKeyReplenish('OneKey')"
        >一键补货</el-button>
      </el-col>
      <el-col :span="16">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          inline
        >
          <el-form-item label="取整箱方式" prop="resource">
            <el-radio-group
              v-model="ruleForm.resource"
              @change="changeResource"
            >
              <el-radio label="0">向上取整箱</el-radio>
              <el-radio label="1">向下取整箱</el-radio>
              <el-radio label="2">不取整箱数</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="强制整箱">
            <el-switch
              v-model="ruleForm.fullContainerLoad"
              @change="changeResource(ruleForm.resource)"
            />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <vxe-table
      ref="xTableRef"
      :footer-method="footerMethod"
      show-footer
      :row-class-name="rowClassName"
      border
      auto-resize
      resizable
      :scroll-y="{enabled: true,}"
      :scroll-x="{enabled: true,}"
      :loading="tableLoading"
      max-height="500"
      :data="
        tableDatas.filter((v) => {
          if (isActiveNum === 2) {
            return v.replenishNumber && v.replenishNumber != 0;
          } else {
            return v;
          }
        })
      "
      align="center"
      class="mb-35"
      :edit-rules="validRules"
      auto-size
      :edit-config="{ trigger: 'click', mode: 'cell', showStatus: true }"
      @edit-actived="editActivedEvent"
    >
      <vxe-column field="style" title="Style" width="150" />
      <vxe-column field="color" title="Color" width="120" />
      <vxe-column field="size" title="Size" width="120" />
      <vxe-column field="sku" title="Sku" width="240" />
      <vxe-column
        field="outStockChannelName"
        title="调出备货渠道"
        width="120"
      />
      <vxe-column field="inStockChannelName" title="调入备货渠道" width="120" />
      <vxe-column field="platSku" title="Platsku" width="120" :edit-render="{}">
        <template #default="{ row }">
          <vxe-select
            v-if="isActiveNum === 1"
            v-model="row.platSku"
            transfer
            :disabled="row.fnskuUpc && row.fnskuUpc.length == 1"
            @change="(e) => platSkuChange(e.value, row)"
          >
            <vxe-option
              v-for="(item, index) in row.fnskuUpc"
              :key="index && item.platSku"
              :value="item.platSku"
              :label="item.platSku"
            />
          </vxe-select>
          <span v-else>
            {{ row.platSku || null }}
          </span>
        </template>
        <template #edit="{ row }">
          <vxe-select
            v-if="isActiveNum === 1"
            v-model="row.platSku"
            transfer
            :disabled="row.fnskuUpc && row.fnskuUpc.length == 1"
            @change="(e) => platSkuChange(e.value, row)"
          >
            <vxe-option
              v-for="(item, index) in row.fnskuUpc"
              :key="index && item.platSku"
              :value="item.platSku"
              :label="item.platSku"
            />
          </vxe-select>
          <span v-else>{{ row.platSku }}</span>
        </template>
      </vxe-column>
      <vxe-column
        field="platSku"
        title="Fnsku/UPC"
        width="120"
        :edit-render="{}"
      >
        <template #default="{ row }">
          <template v-if="isActiveNum === 1">
            <div>
              <vxe-select v-model="row.platSku" transfer :disabled="true">
                <vxe-option
                  v-for="(item, index) in row.fnskuUpc"
                  :key="index"
                  :value="item.platSku"
                  :label="`${item.fnSku || '无'}/${item.upc || '无'}`"
                />
              </vxe-select>
            </div>
          </template>
          <span v-else>{{ row.fnsku || "无" }}/{{ row.upc || "无" }}</span>
        </template>
        <template #edit="{ row }">
          <vxe-select
            v-if="isActiveNum === 1"
            v-model="row.platSku"
            transfer
            :disabled="true"
          >
            <vxe-option
              v-for="(item, index) in row.fnskuUpc"
              :key="index"
              :value="item.platSku"
              :label="`${item.fnSku || '无'}/${item.upc || '无'}`"
            />
          </vxe-select>
          <span v-else>{{ row.fnsku || "无" }}/{{ row.upc || "无" }}</span>
        </template>
      </vxe-column>
      <vxe-column field="positionName" title="定位" width="80" />
      <vxe-column field="negativeCashFlag" title="是否负现金流" width="100" />
      <vxe-column field="inWarehouseName" title="调入仓库" width="120" />
      <vxe-column field="turnOver" title="补货TurnOver" width="120" />
      <vxe-column field="replenishDemand" title="补货需求" width="120" />
      <vxe-column field="cartonNumber" title="装箱数" width="120" />
      <vxe-column field="outWarehouseName" title="可调出仓库" width="170" />
      <vxe-column
        field="availableInventory"
        title="可用库存数量"
        width="120"
      />
      <vxe-column
        field="replenishNumber"
        title="实际调出数量"
        :edit-render="{}"
        width="160"
      >
        <template #edit="{ row }">
          <el-input-number
            v-if="isActiveNum === 2"
            v-model="row.replenishNumber"
            :max="row.availableInventory"
            :min="0"
            :precision="0"
            :controls="false"
          />
          <vxe-input
            v-else
            v-model="row.replenishNumber"
            :max="row.availableInventory"
            :min="0"
            type="number"
            :controls="false"
            @change="(e) => (row.customQuantity = e.value)"
          />
        </template>
      </vxe-column>
    </vxe-table>

    <div class="btn_bottom">
      <el-button :loading="loading" @click="upStep">上一步</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="submitNext"
      >下一步</el-button>
    </div>
    <el-dialog
      v-if="visible"
      title="补货建议生成-仓库确认"
      :visible.sync="visible"
      width="900px"
      center
      :close-on-click-modal="false"
    >
      <vxe-table
        ref="xTable"
        border
        max-height="1000"
        class="sortable-row-demo"
        :data="list"
        align="center"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxChange"
      >
        <vxe-table-column type="checkbox" width="80" />
        <vxe-table-column field="warehouseName" title="仓库" />
        <vxe-table-column field="warehouseType" title="仓库类型" />
        <vxe-table-column title="优先级(拖动顺序)" width="160">
          <template v-slot="{row}">
            <span class="drag-btn">
              <i class="vxe-icon--menu" />
            </span>
          </template>
        </vxe-table-column>
      </vxe-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { trackDimensionEvent, handleBreadTitle } from '@/utils/monitor'
import XEUtils from 'xe-utils'
import { replenishSkuValidate, postReplenishNext } from '@/api/supply'
import Suggestion from './Suggestion'
import { deepClone } from '@/utils'
import Sortable from 'sortablejs'
import { replenishSuggest } from '@/api/supply'
import VXETable from 'vxe-table'
import { mapGetters } from 'vuex'
import { replenishNextMove } from '@/api/supply'
import NP from 'number-precision'

export default {
  components: { Suggestion },
  props: {
    stepParams: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Array,
      default: () => []
    },
    isActiveNum: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tableLoading: false,
      currentData: [],
      queryForm: { styleId: '', skuName: '' },
      pager: { size: 50, current: 1, total: 0 },
      list: [],
      dataSet: [],
      multipleSelection: [],
      visible: false,
      suggestForm: {},
      rules: {
        resource: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      FnskuUpcDisabled: false,
      loading: false,
      ruleForm: {
        resource: '2',
        fullContainerLoad: true

      },
      validRules: {
        replenishNumber: [
          { required: true, message: '请填写' },
          { type: 'number', min: 0 }
        ]
      },
      dialogType: '',
      suggestionDialogVisible: false,
      submitLoading: false,
      tableDatas: [],
      replenishDemandSum: 0
    }
  },
  onActivated() {
    if (this.tableDatas.length) {
      this.tableDatas = deepClone(this.tableDatas)
    }
  },
  computed: {
    suggestParams() {
      this.multipleSelection.map((v, idx) => {
        const { warehouseCode, warehouseName } = v
        this.dataSet.map(vv => {
          if (vv.warehouseCode === warehouseCode && vv.warehouseName === warehouseName) {
            v.sort = vv.sort
          }
        })
      })
      return Object.assign({}, { warehouseList: this.multipleSelection }, this.suggestForm)
    },
    ...mapGetters([
      'replenishmentObject', 'warehouseNameSetStore'
    ]),

    singleStyle() {
      return this.tableDatas.length && this.tableDatas.every(v => v.style === this.tableDatas[0]?.style)
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        this.rowDrop()
        this.dataSet = deepClone(this.warehouseNameSetStore)
        this.list = this.$store.getters.warehouseNameSetStore
        this.setCheckbox()
      } else {
        this.list = deepClone(this.dataSet)
        this.$store.commit('pages/SET_WAREHOUSE_NAME', this.dataSet)
      }
    },
    'stepParams'(val) {
      if (val) {
        this.getTableData()
      }
    }
  },
  methods: {

    sortAndGroupBySKU(data, transferTypeDict) {
      // 创建一个对象来存储分组后的数据
      const groupedData = {}
      // 遍历数据并将其按SKU分组
      data.forEach((item) => {
        const key = item.sku
        if (!groupedData[key]) {
          groupedData[key] = []
        }
        if (item.fnskuUpc.length === 1) {
          const { upc, fnSku, platSku } = item.fnskuUpc[0]
          Object.assign(item, {
            upc,
            fnsku: fnSku,
            platSku
          })
        } else if (item.defaultSku) {
          const { upc, fnSku, platSku } = item.defaultSku
          Object.assign(item, {
            upc,
            fnsku: fnSku,
            platSku
          })
        }
        groupedData[key].push({
          ...item,
          customQuantity: 0,
          replenishNumber: 0,
          transferTypeDict,
          type: transferTypeDict,
          skuName: item.sku,
          stockChannelId: item.outStockChannelId
        })
      })
      // 对每个SKU分组内的数据按availableInventory属性从大到小排序
      for (const sku in groupedData) {
        groupedData[sku].sort(
          (a, b) => b.availableInventory - a.availableInventory
        )
      }
      // 将排序后的数据重新组合成一个数组
      const arrList = Object.values(groupedData)
      arrList.map((arr, i) => {
        arr.map(vv => { vv.rowClassName = i % 2 === 0 ? 'oddclass' : '' })
      })
      return arrList.flat()
    },
    async  getTableData() {
      const { replenishmentObject: { transferTypeDict }} = this
      try {
        this.tableLoading = true
        const { datas: { voList, warehouseNameSet }} = await replenishNextMove(this.stepParams)
        const list = this.sortAndGroupBySKU(voList, transferTypeDict)
        this.tableDatas = Object.freeze(list)

        const newArr = []
        this.replenishDemandSum = 0
        this.tableDatas.forEach((item) => {
          if (!newArr.some((newItem) => newItem.outStockChannelName === item.outStockChannelName && newItem.sku === item.sku)) {
            newArr.push(item)
            this.replenishDemandSum = NP.plus(this.replenishDemandSum, item.replenishDemand)
          }
        })
        this.$refs.xTableRef.updateFooter()

        this.$store.dispatch('pages/setWarehouseNameSet', warehouseNameSet)
      } catch (e) {
        console.log('e: ', e)
      } finally {
        this.$nextTick(() => {
          this.tableLoading = false
        })
      }
    },

    isObjectValues(obj) {
      return Object.values(obj).some(v => (Array.isArray(v) && v.length) || (!Array.isArray(v) && v))
    },

    rowspanMethod({ row, _rowIndex, column, visibleData }) {
      const fields = ['style', 'color', 'size', 'sku']
      const list = ['style', 'color', 'size', 'sku', 'outStockChannelName', 'inStockChannelName', 'list', 'platSku', 'inWarehouseName', 'turnOver', 'replenishDemand', 'cartonNumber', 'negativeCashFlag', 'positionName']
      if (list.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        if (prevRow && fields.every((field) => prevRow[field] && row[field] && (prevRow[field] == row[field]))) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          let nextRow = visibleData[_rowIndex + 1]
          while (nextRow && fields.every((field) => nextRow[field] && row[field] && (nextRow[field] == row[field]))) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    rowClassName({ row, rowIndex }) {
      return this.isActiveNum === 1 && row.rowClassName
    },
    async submit() {
      try {
        if (!this.multipleSelection.length) return this.$message.warning('至少选择一个仓库')
        this.submitLoading = true
        const { datas } = await replenishSuggest(this.suggestParams)
        // 关闭弹窗 更新列表
        this.$message.success('补货建议生成成功')
        this.visible = false
        this.handleUpdateTableDatas(datas)
      } finally {
        this.submitLoading = false
      }
    },
    setCheckbox() {
      this.$nextTick(() => {
        this.$refs.xTable.setCheckboxRow(
          this.list,
          true
        )
      })
      this.multipleSelection = [...this.list]
    },
    handleCheckboxChange({ records }) {
      this.multipleSelection = records
    },
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable
        this.sortable1 = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'), {
          handle: '.drag-btn',
          onEnd: ({
            newIndex,
            oldIndex

          }) => {
            const currRow = this.dataSet.splice(oldIndex, 1)[0]
            this.dataSet.splice(newIndex, 0, currRow)
            this.dataSet = this.dataSet.map((e, idx) => { return { ...e, sort: idx + 1 } })
            // console.log('this.dataSet: ', this.dataSet)
            // console.log('this.data222: ', this.list)
          }
        })
      })
    },

    handleUpdateTableDatas(datas) {
      this.tableDatas.map(v => {
        const { sku, outWarehouseName } = v
        if (this.dialogType === 'Suggest') {
          const obj = datas.find(vv => vv.sku === sku && vv.outWarehouseName === outWarehouseName)
          if (obj) {
            v.replenishNumber = obj.suggestNumber
            v.customQuantity = obj.suggestNumber
          } else if (!datas.find(vv => vv.sku === sku && vv.outWarehouseName === outWarehouseName)) {
            v.replenishNumber = 0
            v.customQuantity = 0
          }
        } else {
          const obj = datas.find(vv => vv.warehouseName === outWarehouseName)
          if (obj) {
            v.replenishNumber = v.availableInventory
            v.customQuantity = v.availableInventory
          } else if (!datas.find(vv => vv.warehouseName === outWarehouseName)) {
            v.replenishNumber = 0
            v.customQuantity = 0
          }
        }
      })
      this.$refs.xTableRef.updateFooter()
    },
    upStep() {
      if (this.isActiveNum === 1) {
        this.ruleForm.resource = '2'
        this.$emit('updadeIsActiveNum', 0)
      }
      // 调拨明细确认回到结果确认处理实际调出数量
      if (this.isActiveNum === 2) {
        this.tableDatas = this.tableDatas.map(v => { return { ...v, replenishNumber: v.defaultReplenishNumber } })
        this.$emit('updadeIsActiveNum', 1)
      }
    },
    async handleNext(list = []) {
      // dsb二期补货翻单流程优化
      this.tableDatas = list.map(v => { return { ...v, defaultReplenishNumber: v.replenishNumber } })
      const arr = list?.filter(v => Number(v.replenishNumber) > 0).map(v => {
        const { sku, platformCode, siteCode, shopCode } = v

        return { siteCode, shopCode, sku, platCode: platformCode }
      })
      await replenishSkuValidate(arr)
      VXETable.modal.message({ status: 'success', content: '校验成功！' })
      this.$emit('updadeIsActiveNum', 2)
    },
    async submitNext() {
      const $table = this.$refs.xTableRef
      const errMap = await $table.validate(true).catch(errMap => errMap)
      if (this.isActiveNum === 1) {
        if (errMap) {
          VXETable.modal.message({ status: 'error', content: '校验不通过！' })
        } else {
          // dsb04校验
          if (this.tableDatas.some(v => (!v.platSku || (!v.fnsku && !v.upc)) && Number(v.replenishNumber) > 0)) {
            return VXETable.modal.message({ status: 'error', content: `实际调出数量>0的SKU对应的platsku、Fnsku必填` })
          }
          // 新增dsb校验
          if (this.tableDatas.every(v => v.replenishNumber == '0')) {
            return VXETable.modal.message({ status: 'error', content: '暂无可用数据, 请重新选择有用数据！' })
          }

          if (this.tableDatas.some(v => v.negativeCashFlag === '是' && Number(v.replenishNumber) > 0)) {
            return VXETable.modal.message({ status: 'error', content: '存在负现金流产品不允许补货' })
          }

          if (this.tableDatas.some(v => v.positionName === 'EOL' && Number(v.replenishNumber) > 0)) {
            this.$confirm('存在EOL产品是否补货', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.handleNext(this.tableDatas)
            })
          } else {
            this.handleNext(this.tableDatas)
          }
        }
      } else {
        if (errMap) return VXETable.modal.message({ status: 'error', content: '校验不通过！' })
        if (this.tableDatas.every(v => v.replenishNumber == '0')) {
          return VXETable.modal.message({ status: 'error', content: '暂无可用数据, 请重新选择有用数据！' })
        }
        this.setNext()
      }
    },
    async setNext() {
      try {
        this.loading = true
        const { datas } = await postReplenishNext(this.tableDatas.filter(v => v.replenishNumber && v.replenishNumber != 0))
        this.loading = false
        this.$emit('getOrderData', datas)

        this.$emit('updadeIsActiveNum', 3)
      } finally {
        this.loading = false
      }
    },
    changeResource(value) {
      setTimeout(() => {
        const { fullContainerLoad } = this.ruleForm
        if (value === '2') {
          this.tableDatas.map(v => Object.assign(v, { replenishNumber: v.customQuantity }))
        } else if (value === '1') {
        // 向下取整箱
          this.tableDatas.map(v => {
          // v.customQuantity 自定义值 v.cartonNumber 装箱数  v.availableInventory 可用库存数量
            const { customQuantity = 0, cartonNumber = 0, availableInventory = 0 } = v
            const per = Math.floor(customQuantity / cartonNumber)
            const num = cartonNumber * per
            if (!cartonNumber) {
              Object.assign(v, { replenishNumber: 0 })
            } else {
              if (!fullContainerLoad) {
                Object.assign(v, { replenishNumber: num > 0 ? (num > availableInventory ? availableInventory : num) : customQuantity })
              } else {
                Object.assign(v, { replenishNumber: num > availableInventory ? (Math.floor(availableInventory / cartonNumber) * cartonNumber) : num })
              }
            }
          })
        } else {
          this.tableDatas.map(v => {
            const { customQuantity = 0, cartonNumber = 0, availableInventory = 0 } = v
            const per = Math.ceil(customQuantity / cartonNumber)
            const num = cartonNumber * per
            const fullLoad = !fullContainerLoad ? availableInventory : (Math.floor(availableInventory / cartonNumber) * cartonNumber)
            if (!cartonNumber) {
              Object.assign(v, { replenishNumber: 0 })
            } else {
              Object.assign(v, { replenishNumber: num > availableInventory ? fullLoad : num })
            }
          })
        }
        this.$refs.xTableRef.updateFooter()
        this.$message.success('取整箱方式操作成功！')
        trackDimensionEvent(handleBreadTitle(this.$route) + `-${this.returnType(this.$route.query.type)}取整箱方式切换至${this.returnResource(value)}`,)
      }, 300)
    },
    returnResource(type) {
      switch (type) {
        case '0':
          return '向上取整箱'
        case '1':
          return '向下取整箱'
        case '2':
          return '不取整箱数'
        default:
          return ''
      }
    },
    returnType(type) {
      switch (type) {
        case '1':
          return '海外调拨补货'
        case '5':
          return '国内快船补货'
        case '2':
          return '国内普船补货'
        default:
          return ''
      }
    },
    platSkuChange(value, row) {
      const { platSku, fnskuUpc = [] } = row
      const list = fnskuUpc.find(v => v.platSku === platSku)
      this.tableDatas.map(item => {
        if (item.sku === row.sku && list) {
          Object.assign(item, { upc: list.upc, fnsku: list.fnSku, platSku: list.platSku })
        }
      })
    },
    editActivedEvent({ rowIndex, row }) {
      this.FnskuUpcDisabled = row.fnskuUpc && row.fnskuUpc.length === 1
    },
    handleOneKeyReplenish(type) {
      this.dialogType = type
      this.suggestionDialogVisible = true
    },
    changeSuggest(dialogType) {
      const { resource: flag, fullContainerLoad: isForce } = this.ruleForm
      Object.assign(this.suggestForm, { flag, isForce, voList: this.tableDatas })
      this.dialogType = dialogType
      this.visible = true

      this.$refs.xTableRef.updateFooter()
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['availableInventory', 'replenishNumber'].includes(column.property)) {
            return XEUtils.sum(this.tableDatas, column.property)
          }
          if (column.property === 'replenishDemand') {
            return this.replenishDemandSum
          }
          return ''
        })
      ]
    }

  }
}
</script>

<style lang="scss" scope>
.sortable-row-demo .drag-btn {
  cursor: move;
  font-size: 16px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
.btn_bottom {
  margin-top:24px;
  display: flex;
  justify-content: center;
}
.oddclass{
  background-color:#f3f3f3;
}
</style>
