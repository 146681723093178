<template>
  <div>
    <div class="gl-cell-card-box">
      <el-table
        ref="tableRef"
        :span-method="spanMethods"
        border
        style="width: 100%"
        max-height="680"
        :data="tableData"
      >
        <el-table-column
          prop="stockChannelName"
          label="备货渠道"
          min-width="120"
        />
        <el-table-column prop="age" label="可调入仓库" min-width="240">
          <template #default="{ row }">
            <div>{{ row.warehouseName }}</div>
            <el-button
              type="text"
              @click="selectWarehouse(row), (isActiveNum = 1)"
            >选择该仓</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="style" label="Style" />
        <el-table-column prop="replenishDemand" label="补货需求" />
        <el-table-column prop="availableInventory" label="可用库存" />
      </el-table>
    </div>

    <div class="btn_bottom">
      <el-button plain @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    stockChannelName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['replenishmentObject']),
    tableData: {
      get() {
        // 拆数据再合并行
        const list = this.value.flatMap((v) => {
          const { voList, warehouseName, warehouseCode, warehouseNameSet } = v
          return v.lineList?.map((vv, index) => ({
            voList,
            warehouseName,
            warehouseNameSet,
            warehouseCode,
            stockChannelName: this.stockChannelName,
            ...vv,
            rowspan: index === 0 ? v.lineList.length : null, // 需要合并的行数 只在合并首行也就是index==0时定义标识
            colspan: 1
          }))
        })
        return list
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    /** 合并表格的第一列，处理表格数据 */
    flitterData(arr) {
      const spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
        } else {
          if (item.stockChannelName === arr[index - 1].stockChannelName) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        }
      })
      return {
        one: spanOneArr
      }
    },
    spanMethods({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.flitterData(this.tableData).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if ([1].includes(columnIndex)) {
        if (row.rowspan && row.rowspan > 0) {
          return {
            rowspan: row.rowspan, // 需要合并的行数
            colspan: 1
          }
        } else {
          // 不合并
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    async selectWarehouse(row) {
      const { replenishmentObject: { result }} = this
      const { voList } = JSON.parse(result)
      const { warehouseCode, warehouseName } = row

      this.$emit('chooseWarehouse', { voList, warehouseCode, warehouseName })
    }

  }
}
</script>
<style scoped lang="scss">
.btn_bottom {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
</style>
